import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { HeadingTag, Maybe, TAGContentAlignment } from '@/types';
import {
	ServiceLineHeroButtonCollection,
	ServiceLineHeroSubHeadline,
	ServiceLineHeroBodyCopy,
	ServiceLineHeroHeadline,
	ContentTypeRichTextContent,
	TermsAndConditions as TTermsAndConditions,
	ServiceLineHeroServicesCollection,
	MedicalService,
	AnalyticsContext,
	ServiceLineHeroContentListCollection,
	ServiceLineHeroContentListItem,
} from '@/types/generated';
import TermsAndConditions from '@/components/TermsAndConditions';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import { customEvent } from '@/utils';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon/TAGSvgIcon';
import ContentfulButton from '@/components/ContentfulButton';
import { usePageThemeContext } from '@/context/PageThemeProvider.ctx';
import TAGButton from '@/components/TAGButton';
import resolveContentfulHeadingTag from '@/utils/resolveContentfulHeadingTag';
import Accordions, { IAccordions } from '@/components/Accordions/Accordions';
import SingleMessage from '@/components/SingleMessage';
import Table from '@/components/Table';

import OfficeInfo from '../OfficeInfo';

import {
	wrapper,
	textContainerStyles,
	ctaButtonWrapperStyles,
	TACWrapperStyles,
	serviceContainerStyles,
	getMedicalServiceButtonStyles,
	getCheckmarkIconStyles,
	contentListWrapperStyles,
} from './ServiceLineHeroContent.styles';

export interface IServiceLineHeroContent {
	headline?: Maybe<ServiceLineHeroHeadline>;
	subHeadline?: Maybe<ServiceLineHeroSubHeadline>;
	bodyCopy?: Maybe<ServiceLineHeroBodyCopy>;
	contentListItems?: ServiceLineHeroContentListCollection['items'];
	buttons?: ServiceLineHeroButtonCollection['items'];
	termsConditions?: Maybe<TTermsAndConditions>;
	servicesCollection?: Maybe<ServiceLineHeroServicesCollection>;
	toggleDrawer: (item: MedicalService) => void;
	activeItemId?: Maybe<string>;
	analyticsContext?: Maybe<AnalyticsContext>;
	parentBackgroundColor?: Maybe<string>;
	isDarkBackground: boolean;
	contentAlign?: Maybe<TAGContentAlignment>;
	eyebrow?: Maybe<string>;
	eyebrowHtag?: Maybe<HeadingTag>;
	showOfficeDetails?: Maybe<boolean>;
	googleMapsBusinessQuery?: Maybe<string>;
	displayOfficeHoursInModal?: Maybe<boolean>;
}

const analyticsClick = (context: Maybe<AnalyticsContext> | undefined) => {
	if (context) {
		customEvent('tagEvent', context, context.eventName);
	}
};

const ContentListItemByType = (props: {
	contentItem: ServiceLineHeroContentListItem;
	contentAlign?: Maybe<string>;
	backgroundColor?: Maybe<string>;
}) => {
	const { contentItem, contentAlign, backgroundColor } = props;
	const type = contentItem?.__typename;
	if (!type) return null;
	const centerAligned = contentAlign === 'Center';

	switch (type) {
		case 'ContentTypeRichText':
			return (
				<ContentTypeRichText
					{...contentItem}
					disableGutters={true}
					centerAlign={centerAligned}
					backgroundColor={backgroundColor}
				/>
			);
		case 'SingleMessage':
			return (
				<SingleMessage
					{...contentItem}
					disableGutters={true}
					contentAlign={contentAlign}
					backgroundColor={backgroundColor}
				/>
			);
		case 'Table':
			return (
				<Table
					{...contentItem}
					containerSx={{ paddingX: 0 }}
					titleHtag={contentItem.titleHtag as HeadingTag}
					backgroundColor={backgroundColor}
				/>
			);
		case 'Accordions':
			return (
				<Accordions
					{...(contentItem as IAccordions)}
					titleAlignment={contentAlign}
					disableGutters={true}
					backgroundColor={backgroundColor}
				/>
			);
		default:
			return null;
	}
};

export default function ServiceLineHeroContent({
	headline,
	eyebrowHtag = 'normal',
	eyebrow,
	subHeadline,
	bodyCopy,
	contentListItems,
	buttons,
	termsConditions,
	servicesCollection,
	toggleDrawer,
	activeItemId,
	isDarkBackground = false,
	contentAlign = 'Left',
	showOfficeDetails = false,
	googleMapsBusinessQuery,
	displayOfficeHoursInModal = false,
	parentBackgroundColor,
}: Readonly<IServiceLineHeroContent>) {
	const pageTheme = usePageThemeContext();
	const pageThemeClassName = pageTheme.replace(' ', '-').toLowerCase();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const centerAligned = contentAlign === 'Center' ? true : false;

	return (
		<Box data-test-id="section_content_service_line_hero" sx={wrapper(centerAligned)}>
			<Box sx={textContainerStyles(centerAligned)} data-test-id={'service_line_hero_content_container'}>
				{eyebrow && (
					<Typography
						data-test-id="service_line_hero_eyebrow_text"
						variant="overline"
						component={resolveContentfulHeadingTag(eyebrowHtag as HeadingTag)}
						color={isDarkBackground ? 'text.light' : 'text.secondary'}
					>
						{eyebrow}
					</Typography>
				)}
				{headline && (
					<ContentTypeRichText
						centerAlign={centerAligned}
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						contentMaxWidth="100%"
						backgroundColor="transparent"
						dataTestId="group_service_line_hero_headline"
						content={headline as ContentTypeRichTextContent}
						docProps={{
							margin: 0,
							color: isDarkBackground ? 'text.light' : 'text.primary',
							variant: isSmallScreen ? 'heroMedium' : 'heroLarge',
						}}
					/>
				)}

				{subHeadline && (
					<ContentTypeRichText
						centerAlign={centerAligned}
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						contentMaxWidth="100%"
						backgroundColor="transparent"
						dataTestId="group_service_line_hero_sub_headline"
						content={subHeadline as ContentTypeRichTextContent}
						docProps={{
							margin: 0,
							color: isDarkBackground ? 'text.light' : 'text.secondary',
							variant: 'header3',
						}}
					/>
				)}

				{bodyCopy && (
					<ContentTypeRichText
						centerAlign={centerAligned}
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						contentMaxWidth="100%"
						dataTestId="group_service_line_hero_body_copy"
						backgroundColor="transparent"
						content={bodyCopy as ContentTypeRichTextContent}
						docProps={{
							variant: 'bodyMediumBook',
							...(isDarkBackground && { color: 'text.light' }),
						}}
					/>
				)}

				{showOfficeDetails && (
					<OfficeInfo
						googleMapsBusinessQuery={googleMapsBusinessQuery}
						displayOfficeHoursInModal={displayOfficeHoursInModal}
					/>
				)}

				{contentListItems && contentListItems?.length > 0 && (
					<Box data-test-id="group_service_line_hero_contentListItems" sx={contentListWrapperStyles}>
						{contentListItems.map((item, index) =>
							item ? (
								<ContentListItemByType
									contentItem={item}
									key={`${item?.sys?.id || ''}-${index}`}
									contentAlign={contentAlign}
									backgroundColor={parentBackgroundColor}
								/>
							) : null
						)}
					</Box>
				)}

				{buttons && buttons.length > 0 && (
					<Box
						data-test-id="group_service_line_hero_cta_container"
						sx={ctaButtonWrapperStyles(centerAligned)}
					>
						{buttons.map((button) =>
							button ? (
								<ContentfulButton
									key={button.sys.id}
									{...button}
									dataTestId="service_line_hero_cta"
									onClick={() => analyticsClick(button.analyticsContext)}
								/>
							) : null
						)}
					</Box>
				)}

				{termsConditions && (
					<Box data-test-id="group_service_line_hero_toc_container" sx={TACWrapperStyles}>
						<TermsAndConditions
							{...termsConditions}
							textColor={isDarkBackground ? 'text.light' : 'text.secondary'}
						/>
					</Box>
				)}

				{servicesCollection && servicesCollection.items && servicesCollection.items.length > 0 && (
					<Box
						data-test-id="group_service_line_hero_services_container"
						sx={serviceContainerStyles(centerAligned)}
					>
						{servicesCollection.items.map((item) =>
							item ? (
								<TAGButton
									size="L"
									variant="outlined"
									onClick={() => toggleDrawer(item)}
									key={item.sys.id}
									sx={getMedicalServiceButtonStyles(theme)}
									className={activeItemId == item.sys.id ? 'active' : ''}
								>
									{activeItemId == item.sys.id && (
										<CheckCircleRoundedIcon sx={getCheckmarkIconStyles(theme)} />
									)}
									{item.iconName && (
										<TAGSvgIcon
											icon={item.iconName as IconName}
											size={40}
											fill="currentColor"
											className={`${pageThemeClassName}`}
										/>
									)}
									<Typography
										color="text.interactive"
										variant="buttonLabel"
										data-test-id="text_medical_service_title"
									>
										{item?.title}
									</Typography>
								</TAGButton>
							) : null
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
}

import { useState } from 'react';

import { Box, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

import {
	ContentfulBackground,
	ContentfulPageThemeOptions,
	HeadingTag,
	Maybe,
	TAGContentAlignment,
	TemplateWidth,
	DisableTemplatePaddingOn,
	TRoundedCornersSize,
} from '@/types';
import {
	ServiceLineHeroBodyCopy,
	ServiceLineHeroHeadline,
	ServiceLineHeroSubHeadline,
	ServiceLineHeroButtonCollection,
	TermsAndConditions,
	FocalPointImage as TFocalPointImage,
	ServiceLineHeroServicesCollection,
	MedicalService,
	Video,
	VideoAsset as TVideoAsset,
	ServiceLineHeroContentListCollection,
} from '@/types/generated';
import ServiceLineHeroContent from '@/components/ServiceLineHero/ServiceLineHeroContent';
import FocalPointImage from '@/components/FocalPointImage';
import { FocalPointData } from '@/components/FocalPointImage';
import { usePageThemeContext } from '@/context/PageThemeProvider.ctx';
import { resolvePageThemePalette } from '@/utils/resolvePageThemePalette';
import { resolveContentfulBgColor, textToJumpLink } from '@/utils';
import VideoAsset from '@/components/VideoAsset';

import TemplateContainer from '../TemplateContainer/TemplateContainer';

import { getContainerStyles, getMediaContainerStyles, mobileImageStyles } from './ServiceLineHero.styles';
import ServiceLineHeroMobileDrawer from './ServiceLineHeroMobileDrawer';
import ServiceLineHeroDesktopDrawer from './ServiceLineHeroDesktopDrawer';

export interface IServiceLineHero {
	/**
	 * The headline for the template.
	 *
	 * @type {Maybe<ServiceLineHeroHeadline>}
	 * @memberof IServiceLineHero
	 */
	headline?: Maybe<ServiceLineHeroHeadline>;
	/**
	 * The eyebrow Heading Tag for the template.
	 *
	 * @type {Maybe<HeadingTag>}
	 * @memberof IServiceLineHero
	 */
	eyebrowHtag?: Maybe<HeadingTag>;
	/**
	 * The eyebrow for the template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IServiceLineHero
	 */
	eyebrow?: Maybe<string>;
	/**
	 * The sub headline for the template.
	 *
	 * @type {Maybe<ServiceLineHeroSubHeadline>}
	 * @memberof IServiceLineHero
	 */
	subHeadline?: Maybe<ServiceLineHeroSubHeadline>;

	/**
	 * The body copy for the template.
	 *
	 * @type {Maybe<ServiceLineHeroBodyCopy>}
	 * @memberof IServiceLineHero
	 */
	bodyCopy?: Maybe<ServiceLineHeroBodyCopy>;

	/**
	 * A collection of content children.
	 *
	 * @type {Maybe<ServiceLineHeroContentListCollection>}
	 * @memberof IServiceLineHero
	 */
	contentListCollection?: Maybe<ServiceLineHeroContentListCollection>;

	/**
	 * The collection of buttons to display. Maximum of 2 buttons.
	 *
	 * @type {Maybe<ServiceLineHeroButtonCollection>}
	 * @memberof IServiceLineHero
	 */
	buttonCollection?: Maybe<ServiceLineHeroButtonCollection>;

	/**
	 * The terms and conditions template.
	 *
	 * @type {Maybe<TermsAndConditions>}
	 * @memberof IServiceLineHero
	 */
	termsConditions?: Maybe<TermsAndConditions>;

	/**
	 * Width of the ServiceLineHero template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof IServiceLineHero
	 * @default 'Full'
	 */
	templateWidth?: Maybe<TemplateWidth>;

	/**
	 * Use the focal point image component to display an image with a focal point.
	 *
	 * @type {Maybe<string>}
	 * @memberof IServiceLineHero
	 */
	focalPointImage?: Maybe<TFocalPointImage>;

	/**
	 * Video Asset with video for Desktop and Mobile
	 *
	 * @type {Maybe<VideoAsset>}
	 * @memberof IServiceLineHero
	 */
	videoAsset?: Maybe<TVideoAsset>;

	/**
	 * A collection of medical services that belongs to a service line.
	 *
	 * @type {Maybe<ServiceLineHeroServicesCollection>}
	 * @memberof IServiceLineHero
	 */
	servicesCollection?: Maybe<ServiceLineHeroServicesCollection>;
	/**
	 * An option to hide the hero image on mobile.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IServiceLineHero
	 */
	hideHeroOnMobile?: Maybe<boolean>;
	/**
	 * An option to display the hero image first and the supporting text second, or vice versa (Mobile Only).
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IServiceLineHero
	 */
	imgDisplaysFirstOnMobile?: Maybe<boolean>;
	/**
	 * An option to display the hero image first and the supporting text second, or vice versa (Desktop Only).
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IServiceLineHero
	 */
	imgDisplaysFirstOnDesktop?: Maybe<boolean>;
	/**
	 * An option to display a border radius on the image.
	 *
	 * @type {Maybe<boolean> | null}
	 * @memberof IServiceLineHero
	 */
	imageWithBorderRadius?: Maybe<boolean> | null;
	/**
	 * An option to set the size of rounded corner on media. (only works when imageWithBorderRadius enabled)
	 *
	 * @type {Maybe<TRoundedCornersSize>}
	 * @memberof IServiceLineHero
	 * @default 'Large'
	 */
	mediaRoundedCornerSize?: Maybe<TRoundedCornersSize>;
	/**
	 * An option to display different background colors.
	 *
	 * @type {Maybe<string>}
	 * @memberof IServiceLineHero
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * An option to display different background colors for Mobile.
	 *
	 * @type {Maybe<string>}
	 * @memberof IServiceLineHero
	 */
	backgroundColorMobile?: Maybe<string>;
	/**
	 * An option to either 'Center' or 'Left' align the content
	 * @type {Maybe<TAGContentAlignment>}
	 * @memberof IServiceLineHero
	 * @default 'Left'
	 */
	contentAlign?: Maybe<TAGContentAlignment> | null;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof IServiceLineHero
	 * @default ['Top', 'Bottom']
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * An option to display office details if any are selected.
	 *
	 * @type {Maybe<boolean> | null}
	 * @memberof IServiceLineHero
	 */
	showOfficeDetails?: Maybe<boolean> | null;
	/**
	 * Optional field for Google Map URL for business query
	 *
	 * @type {Maybe<string>}
	 * @memberof IServiceLineHero
	 */
	googleMapsBusinessQuery?: Maybe<string>;
	/**
	 * Optional field to display hours info in the modal
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IServiceLineHero
	 */
	displayOfficeHoursInModal?: Maybe<boolean>;
	/**
	 * Used to identify a component on a page for the purpose of jumping to it using the anchor link.
	 *
	 * @type {Maybe<string>}
	 * @memberof IServiceLineHero
	 */
	anchorId?: Maybe<string>;
}
export default function ServiceLineHero({
	headline,
	eyebrowHtag = 'normal',
	eyebrow,
	subHeadline,
	bodyCopy,
	termsConditions,
	templateWidth = 'Full',
	contentListCollection,
	buttonCollection,
	focalPointImage,
	videoAsset,
	servicesCollection,
	hideHeroOnMobile = false,
	imgDisplaysFirstOnMobile = false,
	imgDisplaysFirstOnDesktop = false,
	imageWithBorderRadius = true,
	mediaRoundedCornerSize = 'Large',
	backgroundColor = 'White',
	backgroundColorMobile = 'White',
	contentAlign = 'Left',
	disablePaddingOn = ['Top', 'Bottom'],
	showOfficeDetails = false,
	googleMapsBusinessQuery,
	displayOfficeHoursInModal,
	anchorId,
}: Readonly<IServiceLineHero>) {
	const theme = useTheme();
	const { palette } = theme;

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const pageTheme = usePageThemeContext();
	// NOTE: Fixes issue where storybook was not respecting Page Theme flag
	const componentTheme = theme.tagFeatures?.PAGE_THEME_ENABLED
		? deepmerge(theme, {
				palette: resolvePageThemePalette(pageTheme as ContentfulPageThemeOptions, palette),
		  })
		: theme;
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [activeMedicalService, setActiveMedicalService] = useState<Maybe<MedicalService>>(null);
	const backgroundToUse = isSmallScreen ? backgroundColorMobile : backgroundColor;
	const contentfulBackgroundColor = resolveContentfulBgColor(
		backgroundToUse as ContentfulBackground,
		theme,
		componentTheme.palette.backgrounds?.default
	);

	const isDarkBackground = backgroundToUse && backgroundToUse.includes('Dark') ? true : false;

	const toggleDrawer = (item: MedicalService) => {
		if (isDrawerOpen) {
			setDrawerOpen(false);
		}
		setActiveMedicalService(item);
		setDrawerOpen(true);
	};

	const closeDrawer = () => {
		setDrawerOpen(false);
		setActiveMedicalService(null);
	};

	const serviceLineHeroMedia = videoAsset ? (
		<Box
			sx={getMediaContainerStyles({
				theme: componentTheme,
				mediaWithBorderRadius: imageWithBorderRadius,
				isSmallScreen,
				isVideo: true,
				imgDisplaysFirstOnDesktop,
				mediaRoundedCornerSize,
			})}
			key={videoAsset.sys.id}
			data-test-id="service_line_hero_video_asset"
		>
			<VideoAsset
				desktopVideo={videoAsset.desktopVideo as Video}
				mobileVideo={(videoAsset.mobileVideo as Video) || undefined}
			/>
		</Box>
	) : (
		<Box
			sx={getMediaContainerStyles({
				theme: componentTheme,
				mediaWithBorderRadius: imageWithBorderRadius,
				isSmallScreen,
				isVideo: false,
				imgDisplaysFirstOnDesktop,
				mediaRoundedCornerSize,
			})}
			key={focalPointImage?.sys.id}
			data-test-id="service_line_hero_focal_point_image"
		>
			<FocalPointImage
				image={focalPointImage?.image}
				focalPoint={focalPointImage?.focalPoint as FocalPointData}
				sizes={`${theme.breakpoints.down('md').replace('@media', '')} 100vw, 900px`}
				imageSx={isSmallScreen ? mobileImageStyles : {}}
			/>
		</Box>
	);

	const serviceLineHeroContent = (
		<ServiceLineHeroContent
			key={0}
			headline={headline}
			eyebrowHtag={eyebrowHtag}
			eyebrow={eyebrow}
			subHeadline={subHeadline}
			bodyCopy={bodyCopy}
			contentListItems={contentListCollection?.items}
			buttons={buttonCollection?.items}
			termsConditions={termsConditions}
			servicesCollection={servicesCollection}
			toggleDrawer={toggleDrawer}
			activeItemId={activeMedicalService?.sys?.id}
			parentBackgroundColor={backgroundColor}
			isDarkBackground={isDarkBackground}
			contentAlign={contentAlign}
			showOfficeDetails={showOfficeDetails}
			googleMapsBusinessQuery={googleMapsBusinessQuery}
			displayOfficeHoursInModal={displayOfficeHoursInModal}
		/>
	);

	const renderServiceLineHero = () => {
		if (isSmallScreen) {
			const elementsOrder = !imgDisplaysFirstOnMobile
				? [serviceLineHeroContent, serviceLineHeroMedia]
				: [serviceLineHeroMedia, serviceLineHeroContent];

			if (hideHeroOnMobile) {
				return [serviceLineHeroContent];
			}

			return elementsOrder;
		} else {
			return imgDisplaysFirstOnDesktop
				? [serviceLineHeroMedia, serviceLineHeroContent]
				: [serviceLineHeroContent, serviceLineHeroMedia];
		}
	};

	return (
		<ThemeProvider theme={componentTheme}>
			<TemplateContainer
				dataTestId="section_service_line_hero"
				containerSx={getContainerStyles(componentTheme, disablePaddingOn, isSmallScreen)}
				contentfulBackgroundColor={contentfulBackgroundColor}
				templateWidth={(templateWidth as TemplateWidth) || 'Full'}
				{...(anchorId && { id: textToJumpLink(anchorId) })}
			>
				{renderServiceLineHero()}
				{!isSmallScreen && (
					<ServiceLineHeroDesktopDrawer
						activeMedicalService={activeMedicalService}
						closeDrawer={closeDrawer}
						isDrawerOpen={isDrawerOpen}
					/>
				)}
			</TemplateContainer>
			{isSmallScreen && (
				<ServiceLineHeroMobileDrawer
					activeMedicalService={activeMedicalService}
					closeDrawer={closeDrawer}
					setDrawerOpen={setDrawerOpen}
					isDrawerOpen={isDrawerOpen}
				/>
			)}
		</ThemeProvider>
	);
}
